import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
  width?: number | string
  height?: number | string
}

export const useStyles = makeStyles((theme) => ({
  a: (props: Props) => ({
    width: props.width || "22px",
    height: props.height || "100%",
    verticalAlign: "middle",
  }),
  b: {
    fill: theme.palette.info.main,
  },
  c: {
    fill: "none",
  },
  d: {
    fill: "#fff",
  },
}))

const BriefCase: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes.a}
      viewBox="0 0 30 30"
    >
      <g transform="translate(-680 -166.679)">
        <circle
          className={classes.b}
          cx="15"
          cy="15"
          r="15"
          transform="translate(680 166.679)"
        />
        <g transform="translate(686.199 172.878)">
          <path
            className={classes.c}
            d="M0,0H17.563V17.563H0ZM7.318,3.659h2.927V5.123H7.318Zm0,0h2.927V5.123H7.318Z"
          />
          <path
            className={classes.d}
            d="M7.852,12.507v-.731H2.739L2.731,14.7a1.458,1.458,0,0,0,1.463,1.463H14.435A1.458,1.458,0,0,0,15.9,14.7V11.775h-5.12v.731Zm7.315-6.582H12.234V4.463L10.771,3H7.845L6.382,4.463V5.925H3.463A1.467,1.467,0,0,0,2,7.388V9.582a1.458,1.458,0,0,0,1.463,1.463H7.852V9.582h2.926v1.463h4.389A1.467,1.467,0,0,0,16.63,9.582V7.388A1.467,1.467,0,0,0,15.167,5.925Zm-4.389,0H7.852V4.463h2.926Z"
            transform="translate(-0.533 -0.8)"
          />
        </g>
      </g>
    </svg>
  )
}

export default BriefCase
