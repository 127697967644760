import { useMutation } from "@apollo/react-hooks"
import {
  Checkbox as ReactCheckbox,
  createStyles,
  FormControl,
  FormControlLabel as ReactFormControlLabel,
  FormGroup,
  Grid,
  Input,
  Paper,
  Tab as ReactTab,
  Tabs as ReactTabs,
  Theme,
  withStyles,
} from "@material-ui/core"
import { CheckboxProps } from "@material-ui/core/Checkbox"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"
import { isMobile } from "react-device-detect"
import { useSelector } from "react-redux"
import { RootState } from "../../state/rootReducer"
import { useGlobalStyles } from "../../styles/globalStyles"
import TextButton from "../elements/TextButton"
import Modal from "../Modal"
import {
  CREATE_DETAIL_MUTATION,
  UPDATE_DETAIL_CONFIRM_TIMESTAMP,
} from "./graphql"

const ConfirmEmployeeDetails: React.FC<any> = ({ setAllowPaycheck }: any) => {
  const companyName = process.env.COMPANY_NAME || "paycheck"
  const globalStyles = useGlobalStyles()
  const currentUser = useSelector<RootState>(
    (state) => state.auth.currentUser,
  ) as any
  const [openModal, setOpenModal] = React.useState(false)
  const [submitSuccess, setSubmitSuccess] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [hideComponent, setHideComponent] = React.useState(false)
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [submitCheckedDetails, { data, loading, error }] = useMutation(
    CREATE_DETAIL_MUTATION,
  )
  const [submitDetailsConfirm] = useMutation(UPDATE_DETAIL_CONFIRM_TIMESTAMP)

  const setInitialState = () => {
    return {
      personal: {
        nameAndSurname: false,
        idNumber: false,
        mobileNumber: false,
        email: false,
      },
      employer: {
        employeeName: false,
        employeeNumber: false,
        paydayCycle: false,
        nettSalary: false,
      },
      account: {
        bank: false,
        accountBranchCode: false,
        accountNumber: false,
        accountType: false,
      },
    }
  }
  const [state, setState] = React.useState(setInitialState())

  interface StyledTabProps {
    label: string
  }

  /**
   * === Functions ===
   **/

  const handleModalClose = () => {
    setOpenModal(false)
    setSubmitSuccess(false)
    setState({ ...setInitialState() })
  }

  const handleNoClick = () => {
    setOpenModal(true)
  }

  const handleYesClick = () => {
    var today = new Date()
    var timestamp = today.toLocaleString()
    submitDetailsConfirm({
      variables: {
        timestamp,
      },
    })
    setHideComponent(true)
    setAllowPaycheck(true)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue)
  }

  const handleDetailCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const splitName = event.target.name.split(".")
    let newValue = {}
    switch (splitName[0]) {
      case "personal":
        newValue = Object.assign(state.personal, {
          [splitName[1]]: event.target.checked,
        })
        break
      case "employer":
        newValue = Object.assign(state.employer, {
          [splitName[1]]: event.target.checked,
        })
        break
      case "account":
        newValue = Object.assign(state.account, {
          [splitName[1]]: event.target.checked,
        })
        break
    }

    setState({ ...state, [splitName[0]]: { ...newValue } })
  }

  const handleSubmitChangesClick = () => {
    setSubmitting(true)
    submitCheckedDetails({
      variables: {
        ...state.employer,
        ...state.personal,
        ...state.account,
      },
    })
    setHideComponent(true)
    setAllowPaycheck(false)
  }

  /**
   * === Tabs ===
   **/

  const Tab = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "none",
        minWidth: "initial",
        minHeight: "initial",
        marginRight: theme.spacing(3),
        padding: "0 12px 9px 12px",
        color: "#A1A6BD",
        "&:hover": {
          color: theme.palette.primary.main,
          opacity: 1,
        },
        "&$selected": {
          color: theme.palette.primary.main,
        },
        "&:focus": {
          color: theme.palette.primary.main,
        },
      },
      selected: {},
    }),
  )((props: StyledTabProps) => <ReactTab disableRipple {...props} />)

  const Tabs = withStyles((theme) => ({
    root: {
      minHeight: "initial",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  }))(ReactTabs)

  const TabPanel: React.FC<any> = (props: any) => {
    const { children, activeTabIndex, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={activeTabIndex !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        style={{ margin: "18px 0" }}
      >
        {activeTabIndex === index && <>{children}</>}
      </div>
    )
  }

  const tabPanelProps: any = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    }
  }

  /**
   * === Inputs ===
   **/
  const Checkbox = withStyles((theme) => ({
    root: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: theme.palette.primary.main,
      },
      padding: "6px",
    },
    checked: {},
  }))((props: CheckboxProps) => <ReactCheckbox color="default" {...props} />)

  const FormControlLabel = withStyles({
    root: {
      justifyContent: "space-between",
      flex: "auto",
      width: "100%",
      marginLeft: 0,
    },
    label: {
      fontSize: "0.85rem",
    },
  })(ReactFormControlLabel)

  if (hideComponent) {
    return <></>
  }

  if (!loading && submitting) {
    setSubmitting(false)
    setSubmitSuccess(true)
  }

  return (
    <>
      <Paper className={globalStyles.paper} elevation={3}>
        <Grid container>
          <Grid item sm={8}>
            <span style={{ fontSize: isMobile ? "0.7rem" : "0.9rem" }}>
              {/* The above details are used to process your{" "}
              <span style={{ textTransform: "capitalize" }}>{companyName}</span>
              . Are they all correct? */}
              The above details will be used to process your transaction. Is this correct?"
            </span>
          </Grid>
          <Grid item sm={4}>
            <TextButton
              text="Yes"
              style={{ float: "right" }}
              startIcon={<CheckIcon />}
              size="small"
              variant="contained"
              color="primary"
              onClick={handleYesClick}
            />
            <TextButton
              text="No"
              style={{ float: "right" }}
              startIcon={<CloseIcon />}
              size="small"
              variant="contained"
              color="primary"
              onClick={handleNoClick}
            />
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="Mark details to change"
        aria-describedby=""
        title={
          submitSuccess
            ? "Success"
            : `${currentUser.given_name} ${currentUser.family_name}`
        }
        loading={loading}
      >
        {submitSuccess ? (
          <>
            <p>Your submission has successfully been submitted.</p>
            <TextButton
              style={{ margin: 0, width: "100%" }}
              text="Done"
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            />
          </>
        ) : (
          <>
            <p>
              Please select which details are incorrect. This will be confirmed
              with your employer.
            </p>
            <Tabs
              value={activeTabIndex}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Personal" {...tabPanelProps(0)} />
              <Tab label="Employer" {...tabPanelProps(1)} />
              <Tab label="Account" {...tabPanelProps(2)} />
            </Tabs>
            <TabPanel activeTabIndex={activeTabIndex} index={0}>
              <FormGroup row>
                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.personal.nameAndSurname}
                      onChange={handleDetailCheck}
                      name="personal.nameAndSurname"
                    />
                  }
                  label="Name and Surname"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="nameAndSurname" placeholder="Name and Surname" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.personal.idNumber}
                      onChange={handleDetailCheck}
                      name="personal.idNumber"
                    />
                  }
                  label="ID Number"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="idNumber" placeholder="ID Number" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.personal.mobileNumber}
                      onChange={handleDetailCheck}
                      name="personal.mobileNumber"
                    />
                  }
                  label="Contact Number"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="contact_number" placeholder="Contact Number" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.personal.email}
                      onChange={handleDetailCheck}
                      name="personal.email"
                    />
                  }
                  label="Email Address"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="email_address" placeholder="Email Address" />
                </FormControl>
              </FormGroup>
            </TabPanel>
            <TabPanel activeTabIndex={activeTabIndex} index={1}>
              <FormGroup row>
                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.employer.employeeName}
                      onChange={handleDetailCheck}
                      name="employer.employeeName"
                    />
                  }
                  label="Employee Name"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="employeeName" placeholder="Employee Name" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.employer.employeeNumber}
                      onChange={handleDetailCheck}
                      name="employer.employeeNumber"
                    />
                  }
                  label="Employee Number"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="employeeNumber" placeholder="Employee Number" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.employer.paydayCycle}
                      onChange={handleDetailCheck}
                      name="employer.paydayCycle"
                    />
                  }
                  label="Payday Cycle"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="paydayCycle" placeholder="Payday Cycle" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.employer.nettSalary}
                      onChange={handleDetailCheck}
                      name="employer.nettSalary"
                    />
                  }
                  label="Monthly Nett Pay"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="monthly_nett_pay" placeholder="Monthly Nett Pay" />
                </FormControl>
              </FormGroup>
            </TabPanel>
            <TabPanel activeTabIndex={activeTabIndex} index={2}>
              <FormGroup row>
                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.account.bank}
                      onChange={handleDetailCheck}
                      name="account.bank"
                    />
                  }
                  label="Bank"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="bank" placeholder="Bank" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.account.accountBranchCode}
                      onChange={handleDetailCheck}
                      name="account.accountBranchCode"
                    />
                  }
                  label="Account Name"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="account_name" placeholder="Account Name" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.account.accountNumber}
                      onChange={handleDetailCheck}
                      name="account.accountNumber"
                    />
                  }
                  label="Account Number"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="accountNumber" placeholder="Account Number" />
                </FormControl>

                <FormControlLabel
                  labelPlacement={"start"}
                  control={
                    <Checkbox
                      checked={state.account.accountType}
                      onChange={handleDetailCheck}
                      name="account.accountType"
                    />
                  }
                  label="Account Type"
                />
                <FormControl
                  style={{ display: "none" }}
                  fullWidth
                  className={""}
                >
                  <Input id="accountType" placeholder="Account Type" />
                </FormControl>
              </FormGroup>
            </TabPanel>
            <TextButton
              style={{ margin: 0, width: "100%" }}
              text="Submit Changes"
              variant="contained"
              color="primary"
              onClick={handleSubmitChangesClick}
            />
          </>
        )}
      </Modal>
    </>
  )
}

export default ConfirmEmployeeDetails
