import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider as StyledThemeProvider } from "styled-components"

import { ApolloProvider } from "@apollo/client"
import ThemeProvider from "@material-ui/styles/ThemeProvider"

import { getApolloClient } from "./graphql"
import { createStore } from "./state"
import defaultTheme from "./defaultTheme"
import paywellTheme from "./paywellTheme"
import themeSelector from "./utils/themeSelector"

interface Props {
  element?: any
}

const wrapRootElement: React.FC<Props> = ({ element }: Props) => {
  const store = createStore()
  const apolloClient = getApolloClient()
  // Need to check for correct theme
  const theme = themeSelector(process.env.COMPANY_THEME || "default");
  console.log('compname', process.env.COMPANY_THEME)
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>{element}</StyledThemeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default wrapRootElement
