import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
  width?: number | string
  height?: number | string
  styles?: any
  color?: string
}

export const useStyles = makeStyles((theme) => ({
  walletContainer: (props: Props) => ({
    width: props.width || "22px",
    height: props.height || "100%",
    verticalAlign: "middle",
  }),
  walletBody: (props: Props) => ({
    fill: props.color || theme.palette.primary.dark,
  }),
}))

const BalanceWallet: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)

  return (
    <svg
      className={classes.walletContainer}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
    >
      <path
        className={classes.walletBody}
        d="M22.678,7.738V5.214A2.207,2.207,0,0,0,20.492,3H5.186A2.2,2.2,0,0,0,3,5.214v15.5a2.2,2.2,0,0,0,2.186,2.214H20.492a2.207,2.207,0,0,0,2.186-2.214V18.188a2.22,2.22,0,0,0,1.093-1.9V9.642a2.22,2.22,0,0,0-1.093-1.9Zm-1.093,1.9v6.642H13.932V9.642Zm-16.4,11.07V5.214H20.492V7.428H13.932a2.207,2.207,0,0,0-2.186,2.214v6.642A2.207,2.207,0,0,0,13.932,18.5h6.559v2.214Z"
        transform="translate(-0.463 -0.463)"
      />
      <circle
        className={classes.walletBody}
        cx="1.269"
        cy="1.269"
        r="1.269"
        transform="translate(15.51 11.231)"
      />
    </svg>
  )
}

export default BalanceWallet
