import React from "react"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import "./SalaryAdvanceTable.css"
import { formatAmount } from "../../../utils/format"
import { useGlobalStyles } from "../../../styles/globalStyles"

interface Props {
  advances: any[]
}

const SalaryAdvanceTable: React.FC<Props> = ({ advances }: Props) => {
  const globalStyles = useGlobalStyles()
  const companyName = process.env.COMPANY_NAME || "paycheck"

  return (
    <TableContainer>
      <Table>
        <TableHead className={"tableHead"}>
          <TableRow>
            <TableCell>Reference Number</TableCell>
            <TableCell>
              <span style={{ textTransform: "capitalize" }}>{companyName}</span>{" "}
              Date
            </TableCell>
            <TableCell>Earned Amount</TableCell>
            <TableCell>Accessible Amount</TableCell>
            <TableCell>
              <span style={{ textTransform: "capitalize" }}>{companyName}</span>{" "}
              Amount
            </TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {advances.length ? (
            advances.map((advance: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{advance.refNumber}</TableCell>
                <TableCell>{advance.advanceDate}</TableCell>
                <TableCell>
                  {"R " + formatAmount(advance.earnedAmount)}
                </TableCell>
                <TableCell>
                  {"R " + formatAmount(advance.accessibleAmount)}
                </TableCell>
                <TableCell>
                  {"R " + formatAmount(advance.advanceAmount)}
                </TableCell>
                <TableCell color="primary">
                  <div className={advance.status}>
                    {advance.status.toUpperCase()}
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                className={globalStyles.backgroundLightGrey}
                colSpan={6}
              >
                <div
                  className={`${globalStyles.backgroundLightGrey} ${globalStyles.textGrey} ${globalStyles.textCentered}`}
                >
                  You have not yet made any{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {companyName}
                  </span>{" "}
                  withdrawal
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SalaryAdvanceTable
