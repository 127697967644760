import React from "react"
import { Paper } from "@material-ui/core"
import SalaryAdvanceTable from "./tables/SalaryAdvanceTable"
import History from "../icons/History"
import { useGlobalStyles } from "../../styles/globalStyles"
import { BrowserView, MobileView } from "react-device-detect"
import AdvanceHistoryMobileView from "./layout/AdvanceHistoryMobileView"

const SalaryAdvancesHistory: React.FC<any> = ({ advances }: any) => {
  const globalStyles = useGlobalStyles()
  return (
    <Paper className={globalStyles.paper} elevation={3}>
      <h3>
        <History /> History
      </h3>
      <BrowserView>
        <SalaryAdvanceTable advances={advances} />
      </BrowserView>

      <MobileView>
        <AdvanceHistoryMobileView advances={advances} />
      </MobileView>
    </Paper>
  )
}

export default SalaryAdvancesHistory
