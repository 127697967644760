import { withStyles, Input, TableCell, InputAdornment } from "@material-ui/core"

export const PaycheckInput = withStyles((theme) => ({
  root: {
    background: "#ffffff1c",
    borderRadius: "5px",
    padding: "2px 5px",
    color: theme.palette.primary.main,
    fontSize: "13px",
    marginLeft: "32px",
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none!important",
    },
    "& input": {
      padding: "5px 0",
    },
  },
}))(Input)

export const PaycheckInputAdornment = withStyles({
  root: {
    marginRight: "2px",
    "& p": {
      fontSize: "12px",
    },
  },
})(InputAdornment)

export const PaycheckTableCell = withStyles({
  root: {
    padding: "4px",
    color: "#fff",
  },
  head: {
    fontSize: "13px",
  },
  footer: {
    fontWeight: 900,
    fontSize: "13px",
  },
})(TableCell)
