import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import flexiLogo from '../../../images/logos/FlexiWage/flexiWage-colour-logo.png';


interface Props {
  size?: number | string
  width?: number | string
  height?: number | string
}

export const useStyles = makeStyles({
  logo: (props: Props) => ({
    maxWidth: props.width || props.size || "100%",
    maxHeight: props.height || props.size || "100%",
  }),
})

const Logo: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)
  return <img src={flexiLogo} className={classes.logo} />
}

export default Logo
