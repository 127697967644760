/* eslint-disable @typescript-eslint/camelcase */
import { Auth, Logger } from "aws-amplify"
import { FormikHelpers } from "formik"
import { navigate } from "gatsby"
import styled from "styled-components"
import React, { useMemo } from "react"
import { useGlobalStyles } from "../../styles/globalStyles"
import { useSelector } from "react-redux"
import { RootState } from "../../state/rootReducer"
import { isMobile } from "react-device-detect"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import { useNotify } from "../../notifications"
import FormContainer from "./FormContainer"
import ResetPasswordForm, { FormValues } from "./ResetPasswordForm"
import { Wrapper, PageContainer, LogoContainer } from "./SignUpPage"
import CompactFooter from "../CompactFooter"
import { formatPhoneNumber } from "../../utils/format"
import { Logo, SEO } from ".."

interface Props {
  location: any
}

const ResetPasswordContainer = styled.div`
  padding: 1rem 0;
  width: 700px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  @media (max-width: 960px) {
    padding: 120px 50px 0px 50px;
  }

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

const ResetPasswordPage: React.FC<Props> = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(ResetPasswordPage.name), [])
  const notify = useNotify()
  const globalStyles = useGlobalStyles()

  const notifications: any = useSelector<RootState>(
    (state) => state.notifications,
  )

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const { code, password } = values
    const { phone_number } = location.state
    try {
      const phoneNumber = formatPhoneNumber(phone_number)
      await Auth.forgotPasswordSubmit(phoneNumber, code, password)
      notify.success(
        "Your password was reset successfully! You may now sign in.",
      )
      navigate("/sign-in", { replace: true })
    } catch (err) {
      logger.error(err)
      notify.error("Incorrect code. Please try again.")
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <PageContainer>
      <LogoContainer>
        <Logo width={380} height={100} />
      </LogoContainer>
      <Wrapper>
        <ResetPasswordContainer>
          <Container component="main" maxWidth="xs">
            <SEO title="Reset password" />
            <FormContainer>
              <Typography variant="h4" className={globalStyles.fontSemiBold}>
                Reset password
              </Typography>
              {notifications?.notification?.variant === "error" && (
                <div className="erroText">
                  {notifications?.notification?.message}
                </div>
              )}

              {notifications?.notification?.variant === "success" && (
                <div className="successText">
                  {notifications?.notification?.message}
                </div>
              )}
              <Box component="div" mt={5} mx={"auto"}>
                <ResetPasswordForm onSubmit={handleSubmit} />
              </Box>
              <Grid container justify="center">
                <Grid item>
                  <Link
                    href="/forgot-password"
                    color="secondary"
                    className={globalStyles.fontSemiBold}
                  >
                    {"Didn't get the confirmation code? Try again"}
                  </Link>
                </Grid>
              </Grid>
            </FormContainer>
          </Container>
        </ResetPasswordContainer>
      </Wrapper>
      <CompactFooter />
    </PageContainer>
  )
}

export default ResetPasswordPage
