import { Auth } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@material-ui/icons"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import deepOrange from "@material-ui/core/colors/deepOrange"
import { makeStyles } from "@material-ui/core/styles"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"

import { RootState } from "../state/rootReducer"
import PasswordChangeDialog from "./PasswordChangeDialog"
import { setCurrentUser } from "./auth"
import { BrowserView } from "react-device-detect"

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    alignSelf: "center",
    fontSize: "0.8rem",
  },
  avatarMenuItem: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    fontSize: "1rem",
    alignSelf: "center",
    color: "black",
  },
  avatarName: {
    alignSelf: "center",
    color: "black",
    textAlign: "left",
  },
  avatarNames: {
    fontSize: "0.85rem",
  },
  avatarContact: {
    fontSize: "0.65rem",
    color: "#7E84A3",
  },
  button: {
    fontWeight: "normal",
    lineHeight: 1,
  },
  buttonArrow: {
    marginLeft: "20px",
    alignSelf: "center",
  },
  menu: {
    padding: "15px",
  },
  menuItem: {
    width: "230px",
    padding: "0px 30px",
  },
}))

const AccountButton: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const currentUser: any = useSelector<RootState>(
    (state) => state.auth.currentUser,
  )
  const dispatch = useDispatch()
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const avatarLetters = `${currentUser.given_name[0].toUpperCase()}${currentUser.family_name[0].toUpperCase()}`

  const handleMenuOpen = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const handleAccountClick = (): void => {
    navigate("/app/account")
  }

  const handleChangePasswordClick = (): void => {
    setPasswordDialogOpen(true)
    handleMenuClose()
  }

  const handlePasswordDialogClose = (): void => {
    setPasswordDialogOpen(false)
  }

  const handleSignOut = async (): Promise<void> => {
    await Auth.signOut()
    dispatch(setCurrentUser(null))
  }

  return (
    <>
      <Button
        className={classes.button}
        aria-owns={open ? "account-menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <div className={classes.avatarMenuItem}>
          <Avatar className={classes.avatar}>{avatarLetters}</Avatar>
          <BrowserView viewClassName={classes.avatarMenuItem}>
            <div className={classes.avatarName}>
              <span className={classes.avatarNames}>
                {currentUser.given_name} {currentUser.family_name}
              </span>
              <br />
              <span className={classes.avatarContact}>
                {currentUser.email
                  ? currentUser.email
                  : currentUser.phone_number}
              </span>
            </div>
            <KeyboardArrowDownIcon className={classes.buttonArrow} />
          </BrowserView>
        </div>
      </Button>
      <Menu
        id="account-menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleMenuClose}
        className={classes.menu}
      >
        <MenuItem className={classes.menuItem}>
          <strong>
            {currentUser.given_name} {currentUser.family_name}
          </strong>
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Divider style={{ border: "1px solid grey", width: "100%" }} />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleChangePasswordClick}
        >
          Change password
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleSignOut}>
          Sign out
        </MenuItem>
      </Menu>
      <PasswordChangeDialog
        open={passwordDialogOpen}
        onClose={handlePasswordDialogClose}
      />
    </>
  )
}

export default AccountButton
