import React from "react"
import { Grid, Divider, Paper } from "@material-ui/core"
import BankCard from "../icons/BankCard"
import BriefCase from "../icons/BriefCase"
import Person from "../icons/Person"
import { useGlobalStyles } from "../../styles/globalStyles"
import { payCycles } from "../../utils/payCycle"
interface Props {
  employee: any
}

const EmployeeSummary: React.FC<Props> = ({ employee }: Props) => {
  const globalStyles = useGlobalStyles()
  const paycycle = employee.payCycle as string
  return (
    <Grid container spacing={1} justify="center" alignItems="stretch">
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={globalStyles.paper} elevation={1}>
          <h3 className={globalStyles.paperHead}>
            <Person /> Personal Information
          </h3>
          <div className={globalStyles.paperItemWrapper}>
            <span className={globalStyles.paperItemValue}>{`${
              employee.name ?? ""
            } ${employee.surname ?? ""}`}</span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Name and Surname
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.idNumber}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              ID Number
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.mobile}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Contact Number
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.email}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Email
            </span>
          </div>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={globalStyles.paper} elevation={1}>
          <h3 className={globalStyles.paperHead}>
            <BriefCase /> Employee Information
          </h3>
          <div className={globalStyles.paperItemWrapper}>
            <span className={globalStyles.paperItemValue}>
              {employee?.employer?.name}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Employer Name
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.employeeNumber}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Employee Number
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {payCycles[paycycle]}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Payday Cycle
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              R {employee.nettSalary ?? 0}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Monthly Nett Pay
            </span>
          </div>
        </Paper>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Paper className={globalStyles.paper} elevation={1}>
          <h3 className={globalStyles.paperHead}>
            <BankCard /> Account Details
          </h3>
          <div className={globalStyles.paperItemWrapper}>
            <span className={globalStyles.paperItemValue}>
              {employee.bankName}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Bank
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.accountNumber}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Account Number
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.accountType == "cheque_account"
                ? "Cheque Account"
                : "Saving Account"}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Account Type
            </span>
            <Divider />
            <span className={globalStyles.paperItemValue}>
              {employee.celbuxMobileNumber}
            </span>
            <span
              className={`${globalStyles.paperItemName} ${globalStyles.textGrey}`}
            >
              Celbux Number
            </span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default EmployeeSummary
