import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
  width?: number | string
  height?: number | string
}

export const useStyles = makeStyles((theme) => ({
  a: (props: Props) => ({
    width: props.width || "30px",
    height: props.height || "100%",
    verticalAlign: "middle",
  }),
  b: {
    fill: theme.palette.primary.main,
  },
  c: {
    fill: "none",
  },
  d: {
    fill: "#fff",
  },
}))

const Email: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes.a}
      viewBox="0 0 30 30"
    >
      <g transform="translate(-1483 -1021.5)">
        <circle
          className={classes.b}
          cx="15"
          cy="15"
          r="15"
          transform="translate(1483 1021.5)"
        />
        <path
          className={classes.d}
          d="M8.357,1.95a6.357,6.357,0,0,0,0,12.715h3.179V13.393H8.357A5.151,5.151,0,0,1,3.271,8.307,5.151,5.151,0,0,1,8.357,3.221a5.151,5.151,0,0,1,5.086,5.086v.909a1.028,1.028,0,0,1-.954,1,1.028,1.028,0,0,1-.954-1V8.307a3.18,3.18,0,1,0-.928,2.244,2.354,2.354,0,0,0,1.882.935,2.233,2.233,0,0,0,2.225-2.27V8.307A6.36,6.36,0,0,0,8.357,1.95Zm0,8.265a1.907,1.907,0,1,1,1.907-1.907A1.9,1.9,0,0,1,8.357,10.215Z"
          transform="translate(1489.643 1028.164)"
        />
      </g>
    </svg>
  )
}

export default Email
