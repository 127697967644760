import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"

export const useGlobalStyles = makeStyles((theme: Theme) => ({
  button: {
    width: "initial",
    margin: "4px",
    padding: "6px 20px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    fontWeight: "normal",
    fontSize: "13px",
  },
  paper: {
    padding: "15px",
    width: "100%",
  },
  paperHead: {
    marginTop: "0",
    marginBottom: "20px",
  },
  paperItemValue: {
    display: "block",
    marginTop: "1rem",
    fontSize: "0.75rem",
  },
  paperItemName: {
    display: "block",
    fontSize: "0.75rem",
    marginBottom: "1rem",
  },
  paperItemWrapper: {
    paddingLeft: "1.6rem",
  },
  borderLeft: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  borderRight: {
    borderRight: `3px solid ${theme.palette.primary.main}`,
  },
  borderBottom: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  borderTop: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  padding: {
    padding: "15px",
  },
  textCentered: {
    textAlign: "center",
  },
  textDark: {
    color: theme.palette.primary.dark,
  },
  backgroundDark: {
    backgroundColor: theme.palette.primary.dark,
  },
  backgroundMain: {
    backgroundColor: theme.palette.primary.main,
  },
  boldText: {
    fontWeight: "bold",
  },
  textGrey: {
    color: "#7E84A3",
  },
  backgroundLightGrey: {
    backgroundColor: "#F7F9FD",
  },
  modal: {
    width: "360px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px",
    maxHeight: "98%",
    overflowY: "auto",
  },
  modalCloseButton: {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "0",
    minWidth: "initial",
  },
  modalFull: {
    width: "80%!important",
  },
  textLightGrey: {
    color: "#A1A6BD",
  },
  backgroundWhite: {
    backgroundColor: "#FFFFFF",
  },
  fontSize24: {
    fontSize: "24px",
  },
  fontSize30: {
    fontSize: "30px",
  },
  fontSemiBold: {
    fontWeight: 600,
  },
  relative: {
    position: "relative",
  },
}))
