import React from "react"
import { makeStyles } from "@material-ui/core/styles"

interface Props {
  width?: number | string
  height?: number | string
}

export const useStyles = makeStyles((theme) => ({
  a: {
    fill: theme.palette.primary.main,
  },
  b: {
    fill: "#fff",
  },
}))

const Call: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "30"}
      height={props.height || "30"}
      viewBox="0 0 30 30"
    >
      <g transform="translate(-1710 -1021.5)">
        <circle
          className={classes.a}
          cx="15"
          cy="15"
          r="15"
          transform="translate(1710 1021.5)"
        />
        <path
          className={classes.b}
          d="M5.172,4.227a7.556,7.556,0,0,0,.276,1.589l-.736.736a9.1,9.1,0,0,1-.466-2.326h.927M11.223,11.6a7.826,7.826,0,0,0,1.6.276v.914a9.467,9.467,0,0,1-2.332-.46l.736-.73M5.762,3H3.614A.615.615,0,0,0,3,3.614,10.432,10.432,0,0,0,13.432,14.046a.615.615,0,0,0,.614-.614V11.291a.615.615,0,0,0-.614-.614,7,7,0,0,1-2.191-.35.515.515,0,0,0-.19-.031.629.629,0,0,0-.436.178l-1.35,1.35A9.3,9.3,0,0,1,5.222,7.781l1.35-1.35A.616.616,0,0,0,6.725,5.8a6.972,6.972,0,0,1-.35-2.191A.615.615,0,0,0,5.762,3Z"
          transform="translate(1716.477 1027.977)"
        />
      </g>
    </svg>
  )
}

export default Call
