import { createStyles, makeStyles, Theme } from "@material-ui/core"
import Avatar from '@material-ui/core/Avatar';
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon } from "@material-ui/icons"
import clsx from "clsx"
import React, { useState } from "react"
import { BrowserView, isMobile, MobileView } from "react-device-detect"
import AccountButton from "./AccountButton"
import DrawerList from "./DrawerList"
import { Logo } from "./elements"
import PaycheckIcon from "../images/favicon.png"


const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const DRAWER_WIDTH = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: "#ffffff",
      color: "#131523",
      boxShadow: "none",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      border: "none",
      position: "relative",
      whiteSpace: "nowrap",
      width: DRAWER_WIDTH,
      height: "100vh",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    drawerList: {
      width: 250,
    },
    publishButton: {
      marginRight: theme.spacing(2),
    },
    paycheckIcon: {
      height: 64,
      width: 64,
    },
    viewContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    }
  }),
)

interface MiniDrawerProps {
  open: boolean
  onClose: () => void
}

const MiniDrawer: React.FC<MiniDrawerProps> = ({
  open,
  onClose,
}: MiniDrawerProps) => {
  const classes = useStyles()
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <Box ml="8px">
          <Logo height={100} />
        </Box>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <DrawerList hidden={!open}/>
    </Drawer>
  )
}

interface SwipeableTemporaryDrawerProps {
  open: boolean
  onClose: () => void
  onOpen: () => void
}

const SwipeableTemporaryDrawer: React.FC<SwipeableTemporaryDrawerProps> = ({
  open,
  onClose,
  onOpen,
}: SwipeableTemporaryDrawerProps) => {
  const classes = useStyles()
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
        <div className={classes.drawerList}>
          <DrawerList hidden={false} />
        </div>
      </div>
    </SwipeableDrawer>
  )
}

interface AppNavProps {
  title: string
}

const AppNav: React.FC<AppNavProps> = ({ title }: AppNavProps) => {
  const [open, setOpen] = useState(isMobile ? false : true)
  const classes = useStyles()
  const shiftAppBar = useMediaQuery("(min-width:960px)")

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          open && shiftAppBar && classes.appBarShift,
        )}
      >
        <Toolbar>
          <BrowserView viewClassName={classes.viewContainer}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && shiftAppBar && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <AccountButton />
          </BrowserView>

          <MobileView viewClassName={classes.viewContainer}>
            <Avatar className={classes.paycheckIcon} alt="Paycheck Icon" src={PaycheckIcon}/>
            <AccountButton />
          </MobileView>
        </Toolbar>
      </AppBar>
      <Hidden only={["xs", "sm"]}>
        <MiniDrawer open={open} onClose={handleDrawerClose} />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <SwipeableTemporaryDrawer
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        />
      </Hidden>
    </>
  )
}

export default AppNav
