import {
  Box, Grid,
  makeStyles, Typography
} from "@material-ui/core"
import PropTypes from "prop-types"
import React, { FC, useState } from "react"
import { isMobile } from "react-device-detect"
import ConfirmEmployeeDetails from "../ConfirmEmployeeDetails"
import EarningsAndFunds from "../EarningsAndFunds"
import EmployeeSummary from "../EmployeeSummary"
import SalaryAdvancesHistory from "../SalaryAdvancesHistory"
const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexGrow: 1,
  },
})

const browserOrders = [1, 2, 3, 4]
const mobileOrders = [2, 3, 1, 4]

let orders = isMobile ? mobileOrders : browserOrders

const companyName =
  (process.env.COMPANY_NAME || "paycheck").charAt(0).toUpperCase() +
  (process.env.COMPANY_NAME || "paycheck").slice(1)

const MainContainer: React.FC<any> = ({
  employeeObj,
  refetch,
}: any) => {
  const classes = useStyles()
  const [allowPaycheck, setAllowPaycheck] = useState(false)
  const changeAllowPaycheck = (isAllow:boolean) => {
    setAllowPaycheck(isAllow)
  }
  return (
    <Grid container className={classes.root} spacing={2} justify="center">
      <Grid item hidden={!isMobile}>
        <Typography component="h1" variant="h5" color="inherit" align="center">
          {/* Welcome to your <b>Paycheck</b> */}
          Welcome to your <b>{companyName}</b> Dashboard
        </Typography>
      </Grid>

      <Grid
        container
        item
        xl={8}
        md={10}
        sm={"auto"}
        xs={isMobile ? 11 : 12}
        justify="center"
        spacing={2}
      >
        <Box
          component={Grid}
          display="flex"
          flexWrap="wrap"
          width="100%"
          boxSizing="border-box"
          p={1}
          order={orders[0]}
        >
          <EmployeeSummary employee={employeeObj} />
        </Box>
        <Box
          component={Grid}
          display="flex"
          flexWrap="wrap"
          width="100%"
          boxSizing="border-box"
          p={1}
          order={orders[1]}
        >
          <ConfirmEmployeeDetails  setAllowPaycheck={changeAllowPaycheck}/>
        </Box>
        <Box
          component={Grid}
          display="flex"
          flexWrap="wrap"
          width="100%"
          boxSizing="border-box"
          p={1}
          order={orders[2]}
        >
          <EarningsAndFunds employee={employeeObj} refetch={refetch} allowPaycheck={allowPaycheck}/>
        </Box>
        <Box
          zIndex={isMobile ? 1300 : 0}
          component={Grid}
          display="flex"
          flexWrap="wrap"
          width="100%"
          boxSizing="border-box"
          p={1}
          order={orders[3]}
        >
          <SalaryAdvancesHistory advances={employeeObj.salaryAdvances} />
        </Box>
      </Grid>
    </Grid>
  )
}

MainContainer.propTypes = {
  className: PropTypes.string,
}

export default MainContainer
