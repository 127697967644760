import { createMuiTheme } from "@material-ui/core/styles"


declare module "@material-ui/core/styles" {
    interface Palette {
      appContainerBackground: string
    }
  
    interface PaletteOptions {
      appContainerBackground: string
    }
  }
  
  const flexiTheme = createMuiTheme({
    typography: {
      fontFamily: [
        "Nexa",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontWeight: "bold",
      },
      h2: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
      },
      body1: {
        fontWeight: "normal",
      },
      body2: {
        fontWeight: "lighter",
      },
    },
    palette: {
      primary: {
        main: "#A4CA39",
        dark: "#009588",
      },
      secondary: {
        main: "#009588",
        dark: "#458B89",
        light: "#f7f9fd",
      },
      background: {
        default: "#fff",
      },
      text: {
        primary: "#05B04B",
        secondary: "#05B04B",
      },
      error: {
        main: "#b11f24",
      },
      info: {
        main: "#A4CA39",
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          borderRadius: "2px",
          padding: "12px",
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "14pt",
          width: "180px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        text: {
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "14pt",
        },
      },
    },
  });
  
  export default flexiTheme;